export class Pagination {
    pageNo: number;
    pageMaxSize: number;

    limit: number;
    skip: number;

    total: number;

    // start: number;
    // end: number;

    constructor() { }
}

export class PaginationControlsOptions {
    page: number;
    limit: number;
    skip: number;
    total: number;
    constructor() { }
}

