export const FUELID = {
    Diesel_Red_ID_DEV: '5dfb269099e0ae3ed06026ad',
    Diesel_Red_ID_LIVE: '5ed565091325030dc5419341',

    Diesel_Clear_ID_DEV: '5d2896907d3a1b27efed79ba',
    Diesel_Clear_ID_LIVE: '5d2896907d3a1b27efed79ba',

    Unleaded_ID_DEV: '5d285d3ecfe4c722b8e50fed',
    Unleaded_ID_LIVE: '5d285d3ecfe4c722b8e50fed',

    Premium_ID_DEV: '5d285d80cfe4c722b8e50fee',
    Premium_ID_LIVE: '5d285d80cfe4c722b8e50fee',
};
