export const FLEET_TELEMATICS = [
    { value: 'NONE', id: 0, url: '' },
    { value: 'MAPBOX', id: 1, url: 'logo1.svg' },
    { value: 'OMNITRACS', id: 2, url: 'logo2.svg' },
    { value: 'LYTX', id: 3, url: 'logo3.svg' },
    { value: 'KEEP_TRUCKIN', id: 4, url: 'logo4.svg' },
    { value: 'SAMSARA', id: 5, url: 'logo5.svg' },
    { value: 'VERIZON_CONNECT', id: 6, url: 'logo6.svg' },
    { value: 'GEO_TAB', id: 7, url: 'logo7.svg' },
    { value: 'JJ_KELLER_AND_ASSOCIATES', id: 9, url: 'logo8.png' },
    { value: 'OTHER', id: 8, url: '' },
];
