export const bookingStatus = [
    { status: 10, text: 'Pending', statusKey: 'PENDING' },
    { status: 1, text: 'Accept', statusKey: 'ACCEPT' },
    { status: 2, text: 'On the way', statusKey: 'ONTHEWAY' },
    { status: 3, text: 'Reached', statusKey: 'REACHED' },
    { status: 4, text: 'Order started', statusKey: 'STARTSERVICE' },
    { status: 5, text: 'Order ended', statusKey: 'ENDSERVICE' },
    { status: 6, text: 'User not found', statusKey: 'USERNOTFOUND' },
    { status: 8, text: 'Cancel', statusKey: 'CANCEL' },
    { status: 9, text: 'Expire', statusKey: 'EXPIRE' },
    { status: 14, text: 'Inprocess', statusKey: 'INPROCESS' }
];

export const bookingRecurringStatus = [
    { text: 'All', statusKey: 'ALL' },
    { text: 'Active', statusKey: 'STILL_RECURRING' },
    { text: 'Completed', statusKey: 'DONE' },
    { text: 'Cancelled', statusKey: 'CANCELLED' }
];